/* react-toastify*/
@import 'react-toastify/dist/ReactToastify.css';
@import 'react-datepicker/dist/react-datepicker.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Pretendard Variable', 'Noto Sans KR', -apple-system, sans-serif;
}

#__next {
  width: 100%;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

:root {
  --toastify-toast-min-height: 40px;
}

.react-datepicker {
  padding: 32px 16px;
  border-radius: 16px !important;
  overflow: hidden;
  background: white;
  border: none !important;
  box-shadow: 4px 8px 28px 0px #00000014 !important;
  box-shadow: 0px 4px 12px 0px #00000029 !important;
}

.react-datepicker__header {
  background: white;
  border: none;
}

.react-datepicker__day-names {
  display: flex;
  column-gap: 8px;
}
.react-datepicker__day-name {
  width: 30px;
  margin: 0;
  color: #cbcbcb !important;
}

.react-datepicker__week {
  display: flex;
  column-gap: 8px;
  margin: 8px 0;
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__day {
  width: 30px;
  height: 30px;
  margin: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #eeeeee;
  }
}

.react-datepicker__day--selected {
  border-radius: 100%;
  background: #0d4fde;

  &:hover {
    border-radius: 100%;
    background: #0d4fde;
  }
}

.react-datepicker__day--keyboard-selected {
  background: white;
  border: none;
  outline: none;
}

.react-datepicker__day-name:nth-child(6n + 1) {
  color: #ff5c5c !important;
}
